export const VERIFIED_CONTACT_STATUS = {
  VERIFIED: 'VERIFIED',
  ALTERNATE_VERIFIED: 'ALTERNATE_VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
} as const;

export const VERIFIED_CONTACT_STATUS_LABEL = {
  VERIFIED: 'Verified',
  ALTERNATE_VERIFIED: 'Alternate verified',
  UNVERIFIED: '',
} as const;
